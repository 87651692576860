.Projectpage {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  width: 350px;
}
.Projects {
  border: 1px solid #000000;
  /* width: 900px;
  height: 500px; */
  width: 300px;
  height: 200px;
  margin: 10px 10px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.9);
}

.flip-card-front {
  /* border: 1px solid green; */
  margin-top: 20px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-radius: 5px;
}

.projectHeader {
  display: flex;
  justify-content: space-between;
}

.projectHeader h3 {
  padding-left: 10px;
}

@media screen and (max-width: 800px) {
  #Projects {
    width: 300px;
    height: 200px;
  }
}

/* flip styles below */
/* .projectsCard{
    transition: transform 0.8s;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.projectsCard:hover .flip-card-inner{
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.flip-card-back{
    position: absolute;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */
