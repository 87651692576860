#skillimg {
  width: 80px;
  height: 80px;
  padding: 10px;
}

#skillimg:hover {
  transform: scale(1.2, 1.2);
}

#imgbox {
  margin: 3px;
  width: 80px;
}

#namep {
  opacity: 0;
  visibility: hidden;
  margin-bottom: -9px;
  transition: visibility 0s, opacity 0.2s linear;
}

#skill-box:hover #namep {
  opacity: 1;
  visibility: visible;
  /* transform: scale(1.2, 1.2); */
}

@media (prefers-reduced-motion: no-preference) {
  .imgSpinner {
    animation: App-logo-spin infinite 9s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .imgSpinnerBackwards {
    animation: App-logo-spin-backwards infinite 9s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin-backwards {
  from {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
