#myHeader a {
  padding: 0 10px;
  margin: 0 20px;
  font-size: 20px;
  text-decoration: none;
  color: #fff;
}

#navbar {
  /* width: 900px; */
  border: 1px solid #182a3e;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.9);
  /* background-color: #000; */
  background-color: #182a3e;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: center;
  margin-bottom: -15px;
}

/* #navbar div {
  display: flex;
} */

.sticky-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
}

.sticky .sticky-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

@media screen and (max-width: 800px) {
  #navbar {
    /* width: 234px; */
    width: 95%;
    display: flex;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    margin-bottom: -45px;
  }
}
