#profilepicdiv {
  z-index: 99;
  background-image: url("/public/me-saw-min.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  border: 5px solid #fff;
  margin-left: 15px;
  margin-right: 290px;
}

#profileDiv {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/public/atx-min.jpeg");
  /* url("https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/austin-skyline-at-night-7-4-rob-greebon.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.9);
  z-index: 0;
}

#nameDiv {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  color: #fff;
}

#bigDiv {
  width: 72%;
}

#hugeDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

@media screen and (max-width: 1200px) {
  #bigDiv {
    /* width: 250px; */
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  #hugeDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #profileDiv {
    display: flex;
    flex-direction: column;
    height: 500px;
  }

  #profilepicdiv {
    margin-left: 290px;
    margin-top: 15px;
  }

  #nameDiv {
    margin-top: 90px;
    margin-left: -2px;
  }
}
