#footerDiv {
  margin-top: 80px;
  border-top: 2px solid black;
  background-color: #bfbfbf;
}

#footerThings {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#socialIcons {
  display: flex;
  /* border: 1px solid green; */
  width: 300px;
  justify-content: center;
  align-items: center;
}

#technology {
  /* border: 1px solid green; */
  width: 300px;
}

table {
  border: 1px solid black;
  border-radius: 90px;
}

.icons {
  margin: 10px;
}

.icons i {
  color: black;
}

.icons i:hover,
img:hover {
  transform: scale(1.2, 1.2);
}

@media screen and (max-width: 800px) {
  #footerThings {
    display: flex;
    flex-direction: column;
  }
}
