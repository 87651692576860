.App {
  text-align: center;
}

/* ============ animation ============= */
/* use the below somewhere for fun */
/* @media (prefers-reduced-motion: no-preference) {
  #skillimg {
    animation: App-logo-spin infinite 4s linear;
  }
} */

@keyframes App-logo-spin {
  /* from {
    transform: rotate(0deg);
  } */
  50% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(-30deg);
  }
}

#majorskilldiv {
  display: flex;
  justify-content: center;
}

#skillsdiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -50px;
  margin-bottom: 30px;
  /* width: 750px; */
  width: 72%;
  border: 20px solid #000a27;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.9);
}

.projectDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.reversedDiv {
  flex-direction: row-reverse;
}

.profileCont {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 909px) {
  #skillsdiv {
    width: 100%;
  }
}
